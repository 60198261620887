import { FC, useEffect } from "react"
import { clearAccountId } from "../utils/amplitude"
import config from "../../config.json"

import { WrappedBase } from "../shared-components/Shared/WrappedBase"

const SignOut: FC = () => {
  useEffect(() => {
    window.location.replace(config.authURL + "/logout")
    clearAccountId()
  })

  return (
    <WrappedBase
      metaTitle="Sign out"
      metaDescription=""
      canonicalUrl="https://www.habito.com/sign-out"
      noIndex={true}
      pageName="Sign out"
      intercom={false}
      config={config}
    />
  )
}

export default SignOut
